.test {




}


.region-selector {
	&__interactive {
		svg { display: none; }
		img { display: block; }
	}
	&__helper {
		display: none;
	}
}
.form__field--select {
	.form__container {
		&:before {
			display: none;
		}
	}
	select {
		padding-left:0;
	}
}